import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {withdrawalsHistory} from '../Request/Histories';
import { accountBalance, referralBalance, bonusBalance } from '../Request/Wallets';
import ChartBalance from '../Reinvestment/ChartBalance';
import EarningSlides from '../Investment/EarningSlides';
const Withdrawal = () => {

    let totalWithdrawalbles = accountBalance() + referralBalance() + bonusBalance();

    let myWithdrawalHistory = withdrawalsHistory();

    const [pendingWithdrawals, setPendingWithdrawal] = useState(0);
    const [activeWithdrawal, setActiveWithdrawal] = useState(0);
    const [completedWithdrawal, setcompletedWithdrawal] = useState(0);
    const getDate = (date) => new Date(date).toDateString();

    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
   
   
    useEffect(() => {
        document.title = 'WITHDRAWAL';
        myWithdrawalHistory = withdrawalsHistory();
        totalWithdrawalbles = accountBalance() + referralBalance() + bonusBalance();

        let pendingNum = 0;        
        let activeNum = 0;        
        let completedNum = 0;  

        myWithdrawalHistory.forEach(Withdrawal => {
                
                if (Withdrawal.status === 'approved') {
                    activeNum += parseInt(Withdrawal.amount);
                }
                if (Withdrawal.status === 'completed') {
                    completedNum += parseInt(Withdrawal.amount);
                }
                if (Withdrawal.status === 'pending') {
                    pendingNum += parseInt(Withdrawal.amount);
                }
                if (Withdrawal.status === 'waiting approval') {
                    pendingNum += parseInt(Withdrawal.amount);
                }
            
        });
        
        setActiveWithdrawal(activeNum);
        setcompletedWithdrawal(completedNum);
        setPendingWithdrawal(pendingNum);
        
        if(window && window.init_template){
            window.init_template();
        }  
    }, [myWithdrawalHistory, activeWithdrawal, completedWithdrawal, pendingWithdrawals]);

  return (
    <div className="container px-sm-4 px-0" style={{marginTop: '60px'}}>
        <EarningSlides/>
        <div className="row text-center mt-4">        
            <div className="col-12 mb-n2 text-start">
                <Link to="/withdrawal" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="default-link card card-style mx-0" style={{height: "90px"}}>
                    <div className="card-center px-4">
                        <div className="d-flex">
                            <div className="align-self-center">
                                <span className="icon icon-m rounded-s gradient-green shadow-bg shadow-bg-xs">
                                <i translate="no" className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>money_off_csred</i>
                                </span>
                            </div>
                            <div className="align-self-center ps-3 ms-1">
                                <h1 className="font-20 mb-n1">Request Withdrawal</h1>
                                <p className="mb-0 font-12 opacity-70">Withdraw your earnings</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>		
        </div>        
<div className="card card-style mx-0">
   <div className="content mb-0">
       <div className="tabs tabs-pill" id="lend-customers">
           <div className="tab-controls rounded-m p-1 overflow-visible">
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Approved</a>
               <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Declined</a>
           </div>
           <div className="mt-3"></div>
           {/* <!-- Tab Bills List --> */}
        <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
            <div className="content mt-0 mb-0">
                {!pendingWithdrawals > 0 ?
                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    No pending Withdrawal records found for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    myWithdrawalHistory.map(Withdrawal =>(
                        (Withdrawal.status === 'pending' || Withdrawal.status === 'waiting approval') &&
                        <Link key={Withdrawal.id} data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(Withdrawal.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(Withdrawal.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-red-dark">{Withdrawal.wallet_name}</h4>
                                <p className="mb-0 font-12 opacity-50">{Withdrawal.status}</p>
                            </div>
                        </Link>
                    ))                
                               
                }
            </div>                                          
    </div>

    <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
            <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
            <div className="content mt-0 mb-0">
                {!activeWithdrawal > 0 ?
                <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    No active Withdrawal records found for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                    myWithdrawalHistory.map(Withdrawal =>(
                        Withdrawal.status === 'approved' &&
                        <Link key={Withdrawal.id} data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(Withdrawal.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(Withdrawal.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-green-dark">{Withdrawal.wallet_name}</h4>
                                <p className="mb-0 font-12 opacity-50">{Withdrawal.status}</p>
                            </div>
                        </Link>
                    ))                
                            
                }
            </div>                                          
    </div>
    
    {/* <!-- Tab Custom Payments--> */}
    <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
        <div className="content mt-0 mb-0">
            {!completedWithdrawal > 0 ?
            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex">
                        <div className="align-self-center no-click">
                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                        </div>
                        <div className="align-self-center no-click">
                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                No completed Withdrawals record avaialable
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            :
                myWithdrawalHistory.map(Withdrawal =>(
                    Withdrawal.status === 'completed' &&
                    <Link key={Withdrawal.id} data-bs-toggle="offcanvas" data-bs-target="#borrow-history-menu"  to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{getAmount(Withdrawal.amount)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(Withdrawal.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-theme">{Withdrawal.wallet_name}</h4>
                            <p className="mb-0 font-12 opacity-50">{Withdrawal.status}</p>
                        </div>
                    </Link>
                ))                
            
            }
        </div>                   
        </div>
       </div>
   </div>
</div>
    </div>
  )
}

export default Withdrawal