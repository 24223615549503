import React, {Fragment, useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { QRCode } from 'react-qrcode-logo';
import { Link } from 'react-router-dom';
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
  import {CopyToClipboard} from 'react-copy-to-clipboard';

const TransferPin = () => {

    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    const initialpin = {
        oldPin: '',
        NewPin: '',
        confirmPin: ''
    }
    const [error, setErros] = useState('');
    const [pinForm, setpinForm] = useState(initialpin);
    const [buttonText, setbuttonText] = useState(null);

    const onChange = (e) =>
    setpinForm({ ...pinForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postpin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/update-pin`, {
            old_pin: pinForm.oldPin,
            pin: pinForm.NewPin,
            pin_confirmation: pinForm.confirmPin
          });
        },
        {
          onSuccess: (res) => {            
            setSuccessMessage(res.data);
            triggerSuccess();
            setErros('');
          },
          onError: (err) => {    
            let myerror = err.response?.data || err; 
            setErrorMessage(err.response?.data || err);
            triggerError();
            if (myerror.errors) {
                setErros(myerror.errors);
            }           
            
          },
        }
      );


      const { isLoading: isResetingPin, mutate: resetPin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/reset-pin`);
        },
        {
          onSuccess: (res) => {            
            setSuccessMessage(res.data);
            triggerSuccess();
          },
          onError: (err) => {    
            setErrorMessage(err.response?.data || err);
            triggerError();                     
          },
        }
      );

  return (
    <div className="container px-sm-4 px-0" style={{marginTop: '40px'}}>
      <div className="card card-style px-0 bg-white">
            <div className="content text-center d-flex flex-column justify-content-center ">
            <h4 className='mb-2 text-dark'>Scan the QR code below to view your account number or click the button below to copy</h4>  
            <div className='shadow-sm p-2 align-self-center' style={{width: 'fit-content'}}>
            <QRCode qrStyle="dots" value={user.account_number} size={200} logoImage='https://res.cloudinary.com/dzxyvdq14/image/upload/v1673440624/globalleverageappicon.png' />
            </div>           
                
            </div>
            <CopyToClipboard onCopy={() => {setbuttonText('account number copied!');
              setTimeout(() => {
                setbuttonText(null);
              }, 2000);}} text={user.account_number}>
                <button className="btn gradient-green btn-sm m-2 tra-orange-red-hover rounded-pill">
                  {buttonText !== null? buttonText: 'copy account number'}
                </button>
                </CopyToClipboard>
        </div>

    <div className="card bg-theme shadow-sm " style={{borderRadius: '10px'}}>
            <div className="d-flex mx-3 mt-3 py-1">
                <div className="align-self-center">
                    <h1 className="mb-0 text-center">UPDATE TRANSFER PIN</h1>
                </div>
            </div>
            <div className="divider divider-margins mt-3"></div>
            <div className="content mt-0">
                {user.transfer_pin !== null &&
                <Fragment>
                    <div className="pb-3"></div>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <input onChange={onChange} type="number" name="oldPin" className="form-control bg-theme color-theme rounded-xs" id="oldPin" placeholder="enter old transfer pin"/>
                    <label htmlFor="oldPin" className="form-label-always-active color-highlight font-11">Enter Old Transfer Pin</label>
                    <span className="font-10">( required )</span>
                </div>
                </Fragment>
                }
                
                
                <div className="pb-3"></div>
                <div className="form-custom form-label form-icon">
                    
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <input onChange={onChange} type="number" name="NewPin" className="form-control bg-theme color-theme rounded-xs" id="NewPin" placeholder="enter transfer pin"/>
                    <label htmlFor="NewPin" className="form-label-always-active color-highlight font-11">Enter New Transfer Pin</label>
                    <span className="font-10">( required )</span>
                </div>
                <div className="pb-2"></div>

                <div className="pb-3"></div>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <input onChange={onChange} type="text" name="confirmPin" className="form-control bg-theme color-theme rounded-xs" id="confirmPin" placeholder='confirm pin'/>
                    <label htmlFor="confirmPin" className="form-label-always-active color-highlight font-11">Confirm Transfer Pin</label>
                    <span className="font-10">( required )</span>
                </div>
                <div className="pb-2"></div>

                <div className="mt-n2">
                    <Link onClick={resetPin} to="" className="font-14 color-red-light font-weight-bold pt-4 d-block"> {isResetingPin ? 'Please wait while we email your pin....' : 'Forgot Pin? click here'}</Link>
                </div>
            </div>
            
            {error !== '' &&
            <div className='p-4'>
                <div className="alert bg-fade-red color-red-dark alert-dismissible rounded-s fade show" role="alert">
                    <i className="bi bi-exclamation-triangle pe-2"></i>
                    <span className='text-danger'>{error !== '' && error.pin}</span>
                </div>
            </div>
            
            }
            
                

            <div onClick={postpin} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
            {isSendingRequest ?<span>updating...</span>
            :
            <span>Update pin</span>
            }
            </div>
        </div>
</div>
  )
}

export default TransferPin