import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import apiClient from "../../http-common";
import { useMutation } from "react-query";
// import QRCode from "react-qr-code";
import { QRCode } from 'react-qrcode-logo';

import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
  import {CopyToClipboard} from 'react-copy-to-clipboard';

  const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting'}).format(amount);

const FundingPayment = () => {
  const [buttonText, setbuttonText] = useState(null);
  const [linkText, setLinkText] = useState(null);

    let tranx_id = (new URLSearchParams(window.location.search)).get("tranx_id");
    let amount = (new URLSearchParams(window.location.search)).get("amount"); 
    let wallet = (new URLSearchParams(window.location.search)).get("wallet"); 
    let address = (new URLSearchParams(window.location.search)).get("address"); 
    let paymentUrl = window.location.href;

    const [copied, setCopied] = useState(false);
    
    let navigate = useNavigate();

    const { isLoading: isSendingRequest, mutate: postInvestment } = useMutation(
        async () => {
          return await apiClient.post(`/api/save-funding/${tranx_id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            setTimeout(() => {
              window.location.href = '/confirm-fundings';
            }, 2000);
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

      useEffect(() => {
        document.title = 'USER FUNDING PAYMENT';
          setTimeout(() => {
              if (!amount) {
                  navigate('/');
              }
          }, 1000);
      }, [])

  return (
    <div  className='container px-sm-4 px-0' style={{marginTop: '40px'}}>
        <div className="card border border-fade-blue show fade p-0 mb-3">
            <div className="content my-3">
                <div className="d-flex">
                    <div className="">
                        <h2 className="text-center color-theme mb-0 font-500 font-24">
                            MAKE PAYMENT
                        </h2>
                        <p className='color-theme'>
                        kindly pay the sum of {getAmount(amount)} to the provided {wallet} address below<br/> you can copy the address or scan the QR code from your app to pay.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div className="card card-style px-0 bg-white">
            <div className="content text-center justify-content-center d-flex flex-column ">
                 <div className="pb-2 font-18 fw-bold text-dark">{getAmount(amount)} {wallet}</div>
                <div className='shadow-sm p-2 align-self-center' style={{width: 'fit-content'}}>
                <QRCode qrStyle="dots" value={address} size={200} logoImage='https://res.cloudinary.com/dzxyvdq14/image/upload/v1671876369/alphaappicon.png'/>
                </div>                
            </div>

            <div className="d-flex justify-content-between">

                <CopyToClipboard onCopy={() => {setLinkText('payment link copied!');
                  setTimeout(() => {
                    setLinkText(null);
                  }, 2000);}} text={paymentUrl}>
                <button className="btn gradient-red btn-sm m-2 tra-orange-red-hover rounded-pill">
                  {linkText !== null? linkText: 'Copy payment link'}
                </button>
                </CopyToClipboard>


                <CopyToClipboard onCopy={() => {setbuttonText('address copied!');
              setTimeout(() => {
                setbuttonText(null);
              }, 2000);}} text={address}>
                <button className="btn gradient-green btn-sm m-2 tra-orange-red-hover rounded-pill">
                  {buttonText !== null? buttonText: 'copy address'}
                </button>
                </CopyToClipboard>
            </div>

              <div className="card card-style bg-fade-yellow border border-fade-yellow alert alert-dismissible show fade p-0 mb-3">
            <div className="content my-3">
                
                <div className="d-flex">
                    <div className="align-self-center no-click">
                        <i className="bi bi-info-square font-33 color-yellow-dark d-block"></i>
                    </div>
                    <div className="align-self-center no-click">
                        <p className="text-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                           Note: kindly click the button below upon completion of this payment for immediate processing of your payment.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        </div>
        <div className='px-3'>
            <div onClick={postInvestment} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                {isSendingRequest? <span>submitting...</span>
                :
                <span>I have completed this payment</span>
                }                            
            </div>
        </div>
        
    </div>
  )
}

export default FundingPayment