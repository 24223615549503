import React, {useState, useEffect} from 'react';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';
import { accountBalance, referralBalance, bonusBalance } from '../Request/Wallets';

import { useNavigate } from 'react-router-dom';
const InvestmentForm = () => {
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting'}).format(amount);
    const navigate = useNavigate();
    const initialPlan = {
        amount: 0,
        duration: '',
        wallet: '',
    }
    const [planForm, setPlanForm] = useState(initialPlan);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [wallets, setWalets] = useState(null);

    
    const [accBalance, setaccountBalance] = useState(accountBalance());
    const [refBalance, setreferralBalance] = useState(referralBalance());
    const [bonBalance, setbonusBalance] = useState(bonusBalance());

    const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseInt(data.account_balance);
        }
        return balance;
    }
    
    const newreferralBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseInt(data.ref_bal);
        }
        return balance;
    
    }

    const newbonusBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.bonus_balanace;
        }
        return balance;
      }
    
    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });
    
    const { isLoading: isSendingRequest, mutate: postInvestment } = useMutation(
        async () => {
          return await apiClient.post(`/api/save-investment`, {
            plan_id: currentPlan.id,
            wallet_id: planForm.wallet,
            amount: planForm.amount,
            duration: planForm.duration,
          });
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            let redirectUrl = res.data.r_url;
            setTimeout(() => {
                if (redirectUrl === 'dashboard') {
                    window.location.href= '/';
                }else{
                    navigate('/payment'+redirectUrl);
                    // window.location.href= '/payment'+redirectUrl;
                }
                
            }, 2000);
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

      useEffect(() => {
        window.addEventListener('newPlan', function (e) {
            let plan = localStorage.getItem('newPlan');
            if (plan != null) {
                plan = JSON.parse(plan);
                setCurrentPlan(plan);
            }

            let wallets = localStorage.getItem('wallets');
            if (wallets != null) {
                wallets = JSON.parse(wallets);
                setWalets(wallets);
            }

        }, false);   
        
        window.addEventListener('updatedStorage', function (e) {
            setaccountBalance(newaccountBalance());
            setreferralBalance(newreferralBalance());
            setbonusBalance(newbonusBalance());
      }, false);
      })

  return (
    <div id="menu-investment" className="offcanvas offcanvas-bottom offcanvas-attached rounded-bottom">
        {currentPlan !== null &&
            <div className="menu-size" style={{height: "540px"}}>
            <div className="d-flex mx-3 mt-3 py-1">
                <div className="align-self-center">
                    <h1 className="mb-0">{currentPlan.name}</h1>
                </div>
                <div className="align-self-center ms-auto">
                    <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                        <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                    </a>
                </div>
            </div>
            <div className="card card-style bg-fade-blue border border-fade-blue alert alert-dismissible show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex">
                        <div className=" no-click">
                            <i className="bi bi-info-square font-33 color-blue-dark d-block"></i>
                        </div>
                        <div className=" no-click">
                            <p className="color-theme mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                Minimum amount is <strong className='text-success'>{getAmount(currentPlan.min_amount)} </strong> and maximum amount is <strong className='text-success'>{`${getAmount(currentPlan.max_amount) === getAmount(1000000000)? 'unlimited': getAmount(currentPlan.max_amount)}`}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="divider divider-margins mt-3"></div>
            <div className="content mt-0">
                <div className="pb-2"></div>
                <div className="form-custom form-label form-icon">
                <i translate="no" className="material-icons-sharp" style={{fontSize: "17px"}}>attach_money</i>
                    <input onChange={onChange} type="number" name="amount" className="form-control bg-theme text-theme rounded-xs" id="amount" placeholder="Amount"/>
                    <label htmlFor="amount" className="color-highlight form-label-always-">Amount to invest</label>
                    <span>(required)</span>
                </div>
    
                <div className="pb-2"></div>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-sharp" style={{fontSize: "17px"}}>timer</i>
                    <select onChange={onChange} type="text" name="duration" className="form-control bg-theme text-theme rounded-xs" id="duration" placeholder="duration">
                        <option value="">Select duration</option>
                        {currentPlan !== null &&
                        JSON.parse(currentPlan.durations).map(period => (
                            <option key={period} value={period}> {period}</option>
                        ))
                        }
                    </select>
                    <label htmlFor="duration" className="color-highlight form-label-always-">Select Duration</label>
                    <span>(required)</span>
                </div>
    
                <div className="pb-2"></div>
                <div className="form-custom form-label form-icon">
                <i translate="no" className="material-icons-sharp" style={{fontSize: "17px"}}>currency_bitcoin</i>
                    <select onChange={onChange} type="text" name="wallet" className="form-control bg-theme text-theme rounded-xs" id="wallet" placeholder="wallet">
                        <option value="">Select Payment Method</option>
                        {wallets !== null &&
                        wallets.map(wallet => (
                            <option key={wallet.id} value={wallet.id}> {wallet.wallet_name}</option>
                        ))
                        }
                        <option value='account balance'>Account Balance ({getAmount(accBalance)})</option>
                        <option value='referral balance'>Referral Balance ({getAmount(refBalance)})</option>
                    </select>
                    <label htmlFor="wallet" className="color-highlight form-label-always-">Payment Method</label>
                    <span>(required)</span>
                </div>
    
                <div className="pb-3"></div>
                <div onClick={postInvestment} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                    {isSendingRequest? <span>sending...</span>
                    :
                    <span>Submit investment</span>
                    }                            
                </div>
            </div>
        </div>
        }
    </div>
  )
}

export default InvestmentForm