import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { accountBalance, referralBalance, bonusBalance, currentInvestment } from '../Request/Wallets';
// import {currentEarning} from '../Request/Wallets';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Clock from './Clock';

const EarningSlides = () => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1
            }
          }
        ]
      };


    const [accBalance, setaccountBalance] = useState(accountBalance());
    const [refBalance, setreferralBalance] = useState(referralBalance());
    const [bonBalance, setbonusBalance] = useState(bonusBalance());
    const [currentInvested, setcurrentInvested] = useState(currentInvestment());


       const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.account_balance);
        }
        return balance;
    }
    
    const newreferralBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.ref_bal);
        }
        return balance;
    
    }

    const newbonusBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.bonus_balanace;
        }
        return balance;
      }

      const newCurrentInvestment = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.current_investment;
        }
        return balance;
      }


    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }    

    useEffect(() => {
        document.title = 'DASHBOARD';
        if(window && window.init_template){
            window.init_template();
        } 

       window.addEventListener('updatedStorage', function (e) {
        setaccountBalance(newaccountBalance());
        setreferralBalance(newreferralBalance());
        setbonusBalance(newbonusBalance());
        setcurrentInvested(newCurrentInvestment());
        
  }, false);
    });
const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

   return (
<div className='container px-sm-4 px-0 ' style={{marginTop: '40px'}}>    

    <Slider {...settings}>
        <div>
        <div className="card m-0 card-style bg-5 shadow-card shadow-card-m shadow-sm mx-2" style={{height: "150px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1647860735/card4.jpg)"}}>
            <div className="card-top p-3">
                <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
            </div>
            <div className="card-center">
                <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                    <h1 className="font-13 my-n1">
                        <a className="color-theme" data-bs-toggle="collapse" href="#investmnetWalet" aria-controls="investmnetWalet">Click for Balance</a>
                    </h1>
                    <div className="collapse" id="investmnetWalet"><h2 className="color-theme font-26">{getAmount(currentInvested)}</h2></div>
                </div>
            </div>
            <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Invested Amount</strong>
            <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
            <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
            <div className="card-overlay "></div>
        </div>

        </div>
         

        <div >
        <div className="card m-0 card-style bg-5 shadow-card shadow-card-m shadow-sm mx-2" style={{height: "150px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1647860735/card4.jpg)"}}>
            <div className="card-top p-3">
                <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
            </div>
            <div className="card-center">
                <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                    <h1 className="font-13 my-n1">
                        <a className="color-theme" data-bs-toggle="collapse" href="#withdrawable" aria-controls="withdrawable">Click for Balance</a>
                    </h1>
                    <div className="collapse" id="withdrawable"><h2 className="color-theme font-26">{getAmount(accBalance)}</h2></div>
                </div>
            </div>
            <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Account Balance</strong>
            <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
            <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
            <div className="card-overlay "></div>
        </div>

        </div>


        <div>
        <div className="card m-0 card-style bg-5 shadow-card shadow-card-m shadow-sm mx-2" style={{height: "150px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1647860735/card4.jpg)"}}>
            <div className="card-top p-3">
                <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
            </div>
            <div className="card-center">
                <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                    <h1 className="font-13 my-n1">
                        <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet" aria-controls="fundingWallet">Click for Balance</a>
                    </h1>
                    <div className="collapse" id="fundingWallet"><h2 className="color-theme font-26">{getAmount(refBalance)}</h2></div>
                </div>
            </div>
            <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Referral Earning</strong>
            <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
            <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
            <div className="card-overlay "></div>
        </div>

        </div>
        </Slider>    
        <div className='d-flex justify-content-center'>
            <Clock/>
        </div>
</div>
  )
}

export default EarningSlides